<template>
    <div v-if="upcoming && upcoming.length > 0 || finish && finish.length > 0" class="content-wrapper container-fluid"
        style="margin-top: 120px;">

        <div class="container pt-lg--5">
            <div class="card bg-light p-3 rounded-lg" style="border-radius: 24px !important;">
                <div class="card-body">
                    <div class="card-title">
                        <h1 class="text-grey-900 fw-700 font-lg mb-1 d-block">Event <span
                                class="fredoka-font ls-1 fw-700 text-current-eling">Elingway</span> </h1>
                    </div>
                    <div class="card-text w-lg--50">
                        Dapatkan informasi tentang event seru yang bisa kamu datangi untuk bantu persiapan kamu!
                    </div>
                </div>
            </div>
        </div>

        <div v-if="upcoming && upcoming.length > 0" class="container-fluid mt-5 pb-5">
            <div class="row">
                <div class="col-lg-6 mx-auto text-center">
                    <h2 class="my-2 font-xl fw-600">Upcoming Event</h2>
                    <p>Jangan ketinggalan event-event yang akan datang. Pilihlah sesuai dengan minat Anda dan silakan hadir
                        di kota terdekat Anda.</p>
                </div>
            </div>
            <div class="container">
                <div class="row justify-content-center mt-4">
                    <div class="col-md-4 mb-3" v-for="(item, index) in upcoming" :key="index">
                        <div class="card white-bg shadow h-100 mb-3">
                            <router-link :to="{name: 'EventDetailBrowseNew', params: {idEvent: item.id}}" class="position-relative">
                                <div style="height: 348px;">
                                    <img :src=" item.thumbnail" class="img-fluid" style="display: block;">
                                </div>
                            </router-link>
                            <div class="card-body">
                                <div>
                                    <span class="badge badge-secondary mb-3">{{ item.name }}</span>
                                </div>
                                <div class="card-title">
                                    <router-link :to="{name: 'EventDetailBrowseNew', params: {idEvent: item.id}}">
                                        <h5 class="font-lg fw-700 mb-0">
                                            {{ item.title }}
                                        </h5>
                                    </router-link>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-12 col-lg text-center text-lg-left">
                                        <small>Location: {{ item.location }}</small>
                                    </div>

                                    <div class="col-12 col-lg-auto text-center text-lg-right">
                                        <small>{{ formatReadableDate(item.schedule) }}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="finish && finish.length > 0" class="container mt-5 pb-5">
            <div class="row">
                <div class="col-lg-8 mx-auto text-center">
                    <h2 class="my-2 font-xl fw-600">Successful Events</h2>
                    <p>Simak Event mana saja yang sukses diselenggarakan. Acara-acara ini punya reputasi networking yang
                        luas, follow-up action yang positif, dan tentunya sesak dihadiri lebih dari 100 persen target
                        peserta. Penasaran?</p>
                </div>
            </div>
            <div class="container">
                <div class="row justify-content-center mt-4">
                    <div class="col-md-4 mb-3" v-for="(item, index) in finish" :key="index">
                        <div class="card white-bg shadow h-100 mb-3 ">
                            <router-link :to="{name: 'EventDetailBrowseNew', params: {idEvent: item.id}}" class="position-relative">
                                <div style="height: 348px;">
                                    <img :src=" item.thumbnail" class="img-fluid" style="display: block;">
                                </div>
                            </router-link>
                            <div class="card-body">
                                <div>
                                    <span class="badge badge-secondary mb-3">{{ item.name }}</span>
                                </div>

                                <div class="card-title">
                                    <router-link :to="{name: 'EventDetailBrowseNew', params: {idEvent: item.id}}">
                                        <h5 class="font-lg fw-700 mb-0">
                                            {{ item.title }}
                                        </h5>
                                    </router-link>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-12 col-lg text-center text-lg-left">
                                        <small>Location: {{ item.location }}</small>
                                    </div>

                                    <div class="col-12 col-lg-auto text-center text-lg-right">
                                        <small>{{ formatReadableDate(item.schedule) }}</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="testimony && testimony.length > 0" class="container mt-5 pb-5">
            <div class="row">
                <div class="col-lg-8 mx-auto text-center">
                    <h2 class="my-2 font-xl fw-600">Bagaimana <span
                            class="fredoka-font ls-1 fw-700 text-current-eling">Elingway</span> Event Membantu Mereka</h2>
                    <p>Berikut adalah testimoni yang telah hadir di beberapa Elingway Event.</p>
                </div>
            </div>
            <div class="row justify-content-center mt-4">
                <div class="col-md-4 mb-3" v-for="(item, index) in testimony" :key="index">
                    <div class="card h-100 shadow-md ronded-lg">
                        <div style="height: 384px;">
                            <img :src=" item.photo" class="img-fluid" style="display: block;">
                        </div>
                        <div class="card-body">
                            <div class="card-title">
                                <h2 class="fw-600 mb-0">{{ item.name }}</h2>
                                <span class="fw-400 ls-2">{{ item.job }}</span>
                            </div>
                            <div class="card-text font-xsss">
                                {{ item.text }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';
export default {
    data() {
        return {
            media: process.env.VUE_APP_URL_CLOUD,
            isLoad: true,
            upcoming: [],
            finish: [],
            testimony: [],
        }
    },
    created() {
        this.getTestimony()
        this.getEvent()
    },
    methods: {
        async getTestimony() {
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/events/testimony/main`).then(res => {
                this.testimony = res.data.data
                this.isLoad = false
            })
        },
        async getEvent() {
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/events/event/main`).then(res => {
                this.upcoming = res.data.data.upcoming
                this.finish = res.data.data.finish
                this.isLoad = false
            })
        },
        formatReadableDate(schedule) {
            const now = moment();
            const eventDate = moment(schedule);
            const diffDays = eventDate.diff(now, 'days');

            if (diffDays === 0) {
                return 'today';
            } else if (diffDays === 1) {
                return 'tomorrow';
            } else if (diffDays > 1) {
                return `${diffDays} more days`;
            } else if (diffDays === -1) {
                return 'yesterday';
            } else if (diffDays < -1) {
                return `${Math.abs(diffDays)} days ago`;
            } else {
                return 'today or later';
            }
        },
    }
}
</script>
